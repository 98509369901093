import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Box, Loader } from '@wix/design-system';
import { POST_LOGIN_ROUTES } from '../../routes';
import { AppContextProvider } from '../AppLoader';
import ChangeAccountEmail from './ChangeAccountEmail';
import ManagePassword from './ManagePassword';
import ConfirmDetails from './ConfirmDetails';
import ConfirmEmail from './ConfirmEmail';
import Phone from './Phone';
import VerifyCode from './VerifyCode';
import PostLoginOwnerApp2Fa from './OwnerApp2Fa/PostLoginOwnerApp2Fa';

const PostLoginRouter: React.FC<{}> = observer(() => {
  const { rootStore } = useContext(AppContextProvider);
  const { postLoginStore } = rootStore;

  useEffect(() => {
    postLoginStore.init();
  }, []);

  return postLoginStore.isLoaded ? (
    <Switch>
      <Route path={POST_LOGIN_ROUTES.CONFIRM_DETAILS} exact>
        <ConfirmDetails />
      </Route>
      <Route path={POST_LOGIN_ROUTES.CONFIRM_EMAIL} exact>
        <ConfirmEmail />;
      </Route>
      <Route path={POST_LOGIN_ROUTES.CONFIRM_DETAILS_PHONE} exact>
        <Phone.Confirm />;
      </Route>
      <Route path={POST_LOGIN_ROUTES.CONFIRM_DETAILS_PHONE_VERIFY_CODE} exact>
        <VerifyCode.ConfirmDetailsPhone />;
      </Route>
      <Route path={POST_LOGIN_ROUTES.CHANGE_ACCOUNT_EMAIL} exact>
        <ChangeAccountEmail />
      </Route>
      <Route path={POST_LOGIN_ROUTES.CONFIRM_EMAIL_CODE}>
        <VerifyCode.ConfirmEmail />
      </Route>
      <Route path={POST_LOGIN_ROUTES.CHANGE_ACCOUNT_EMAIL_VERIFY_CODE}>
        <VerifyCode.EmailChange />
      </Route>
      <Route path={POST_LOGIN_ROUTES.RECOVERY_PHONE} exact>
        <Phone.Recovery />
      </Route>
      <Route path={POST_LOGIN_ROUTES.RECOVERY_PHONE_VERIFY_CODE}>
        <VerifyCode.RecoveryPhone />
      </Route>
      <Route path={POST_LOGIN_ROUTES.CHANGE_PASSWORD} exact>
        <ManagePassword.Change />
      </Route>
      <Route path={POST_LOGIN_ROUTES.CREATE_PASSWORD} exact>
        <ManagePassword.Create />
      </Route>
      <Route path={POST_LOGIN_ROUTES.PHONE_2FA} exact>
        <Phone.Mfa />
      </Route>
      <Route path={POST_LOGIN_ROUTES.OWNER_APP_2FA} exact>
        <PostLoginOwnerApp2Fa />
      </Route>
      <Route path={POST_LOGIN_ROUTES.PHONE_2FA_AGGRESSIVE} exact>
        <Phone.Mfa aggresive={true} />
      </Route>
      <Route path={POST_LOGIN_ROUTES.PHONE_2FA_VERIFY_CODE} exact>
        <VerifyCode.Phone2FA />
      </Route>
      <Redirect to={POST_LOGIN_ROUTES.CONFIRM_DETAILS} />
    </Switch>
  ) : (
    <Box height="50vh" verticalAlign="bottom" direction="vertical">
      <Loader />
    </Box>
  );
});

export default PostLoginRouter;
