import React, { useCallback, useContext, useState } from 'react';
import {
  AppleLoginButton,
  AppleLoginIcon,
} from '../AppleLoginButton/AppleLoginButton';
import s from './SocialAuth.scss';
import {
  FacebookLoginButton,
  FacebookLoginIcon,
} from '../FacebookLoginButton/FacebookLoginButton';
import {
  GoogleLoginButton,
  GoogleLoginIcon,
} from '../GoogleLoginButton/GoogleLoginButton';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SocialLoginError } from '../SocialLoginButton';
import { TransitionToStudioModal } from '../TransitionToStudioModal/TransitionToStudioModal';
import { Box } from '@wix/design-system';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import { keys } from '../../translationsKeys';
import GoogleIcon from '../../../images/google-icon.png';
import FacebookIcon from '../../../images/fb-icon.png';
import { LOGIN_PAGE_CONTEXT } from '../../utils/constants';

interface SocialAuthProps {
  width?: string;
  withApple?: boolean;
  alignItems?: 'center' | 'flex-end' | 'flex-start';
}

export const UnsupportedSocialWrapper: React.FC<SocialAuthProps> = observer(
  ({ children }) => {
    const {
      rootStore: {
        socialAuthStore,
        emailStepStore,
        displayStore: { isMobile },
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    const [copyLinkText, setCopyLinkText] = useState(
      t('unsupported.browser.for.social.copy.link')
    );
    const copyUrl = useCallback(() => {
      navigator.clipboard.writeText(window.location.href);
      setCopyLinkText(t('unsupported.browser.for.social.copy.link.success'));
    }, []);

    return isMobile && socialAuthStore.isUnsupportedAgentForSocialAuth ? (
      <Box
        direction="vertical"
        marginLeft="auto"
        marginRight="auto"
        marginTop={6}
        className={classNames(s.socialAuthContainer, {
          [s.unsupportedSocialAuth]:
            socialAuthStore.isUnsupportedAgentForSocialAuth,
        })}
      >
        <Box
          direction="horizontal"
          marginTop={1}
          marginBottom={1}
          gap={2}
          className={classNames(s.unsupportedSocialIcons)}
        >
          <img src={GoogleIcon} />
          <img src={FacebookIcon} />
        </Box>
        <Box width="255px" direction="vertical" align="center">
          <Trans
            i18nKey={
              keys[
                emailStepStore.getContext() === LOGIN_PAGE_CONTEXT
                  ? 'unsupported.browser.for.social.login.message'
                  : 'unsupported.browser.for.social.signup.message'
              ]
            }
            values={{ copyLink: copyLinkText }}
          >
            <ThemedText size="small">
              To sign up with Google or Facebook, open this link in another
              browser.
            </ThemedText>
            <ThemedTextButton
              size="small"
              as="a"
              underline="always"
              onClick={copyUrl}
            >
              Copy link
            </ThemedTextButton>
          </Trans>
        </Box>
      </Box>
    ) : (
      <React.Fragment>{children}</React.Fragment>
    );
  }
);

export const SocialButtonsContainer: React.FC<SocialAuthProps> = observer(
  ({ width = '100%', alignItems, children }) => {
    const { t } = useTranslation();
    return (
      <div
        style={{ width, alignItems }}
        role="group"
        aria-label={t('signin_social.more_login_options')}
        className={classNames(s.socialAuthContainer, s.buttons)}
      >
        {children}
        <TransitionToStudioModal />
      </div>
    );
  }
);

export const SocialAuth: React.FC<SocialAuthProps> = observer(({ width }) => {
  const {
    rootStore: { socialAuthStore },
  } = useContext(AppContextProvider);
  return (
    <UnsupportedSocialWrapper>
      <SocialButtonsContainer width={width}>
        {socialAuthStore.showGoogle && <GoogleLoginButton />}
        {socialAuthStore.showFacebook && <FacebookLoginButton />}
        {socialAuthStore.showApple && <AppleLoginButton />}
      </SocialButtonsContainer>
    </UnsupportedSocialWrapper>
  );
});

export const SocialAuthWithoutApple: React.FC<SocialAuthProps> = observer(
  ({ width }) => {
    const {
      rootStore: { socialAuthStore },
    } = useContext(AppContextProvider);
    return (
      <UnsupportedSocialWrapper>
        <SocialButtonsContainer width={width}>
          {socialAuthStore.showGoogle && <GoogleLoginButton />}
          {socialAuthStore.showFacebook && <FacebookLoginButton />}
        </SocialButtonsContainer>
      </UnsupportedSocialWrapper>
    );
  }
);

export const SocialAuthIcons: React.FC<SocialAuthProps> = observer(
  ({ width = '100%', withApple = true, children }) => {
    const {
      rootStore: { socialAuthStore },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    
    return (
      <UnsupportedSocialWrapper>
        <div className={s.socialAuthContainer}>
          <div
            style={{ width }}
            role="group"
            aria-label={t('signin_social.more_login_options')}
            className={classNames(
              s.socialIconsContainer,
              s.icons,
              { [s.mobileIcon] : !withApple }
            )}
          >
            {children}
          </div>
          {socialAuthStore.showIconsError && <SocialLoginError />}
        </div>
      </UnsupportedSocialWrapper>
    );
  }
);

export const SocialAuthIconsWithApple: React.FC<SocialAuthProps> = ({
  width = '100%',
}) => {
  const {
    rootStore: { socialAuthStore },
  } = useContext(AppContextProvider);

  return (
    <SocialAuthIcons width={width}>
      {socialAuthStore.showApple && (
        <AppleLoginIcon className={s.appleIconMobile} />
      )}
      {socialAuthStore.showFacebook && (
        <FacebookLoginIcon className={s.facebookIconMobile} />
      )}
      {socialAuthStore.showGoogle && (
        <GoogleLoginIcon className={s.googleIconMobile} />
      )}
    </SocialAuthIcons>
  );
};

export const SocialAuthIconsWithoutApple: React.FC<SocialAuthProps> = ({
  width = '100%',
}) => {
  const {
    rootStore: { socialAuthStore },
  } = useContext(AppContextProvider);

  return (
    <SocialAuthIcons width={width} withApple={false}>
      {socialAuthStore.showFacebook && (
        <FacebookLoginIcon className={s.facebookIconMobile} />
      )}
      {socialAuthStore.showGoogle && (
        <GoogleLoginIcon className={s.googleIconMobile} />
      )}
    </SocialAuthIcons>
  );
};
