import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {
  RESEND_INDICATION_TTL,
  RESEND_INDICATION_TTL_TIMER,
} from '../utils/constants';
import { constraints } from '../utils/validators';
import { FormField } from './formField';
import { RootStore } from './root';
import { ERROR_CODES } from '../utils/errorHandler';

export abstract class VerifyCodeStore {
  public confirmCode: FormField;
  public status: 'NONE' | 'LOADING' | 'SUCCESS' = 'NONE';
  public resendErrorMessage: string | null;
  public resendSuccessfullyIndication: boolean = false;
  public resendThrottleTimerValue: number = 30;
  public shouldShowResendTimer: boolean = false;
  protected readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      resendSuccessfullyIndication: observable,
      confirmCode: observable,
      resendErrorMessage: observable,
      status: observable,
      resendThrottleTimerValue: observable,
      reactToResend: action,
      resendCode: action.bound,
      verifyCode: action.bound,
      onVerifyConfirmationCode: action.bound,
      onResendConfirmationCode: action.bound,
    });

    this.confirmCode = new FormField(undefined, [
      [
        constraints.minimum(6),
        this.rootStore.i18n.t(
          'confirmEmailModalSecondStep.confirmationCode.errorInfo',
        ),
      ],
      [
        constraints.maximum(6),
        this.rootStore.i18n.t(
          'confirmEmailModalSecondStep.confirmationCode.errorInfo',
        ),
      ],
    ]);
    this.shouldShowResendTimer = rootStore.experiments.enabled(
      'specs.ident.shouldShowResendTimer',
    );
    this.reactToResend();
  }

  public reactToResend() {
    const resendTimeIndication = this.shouldShowResendTimer
      ? RESEND_INDICATION_TTL_TIMER
      : RESEND_INDICATION_TTL;
    reaction(
      () => this.resendSuccessfullyIndication,
      () => {
        this.setResendThrottleTimer();
        setTimeout(
          () => (this.resendSuccessfullyIndication = false),
          resendTimeIndication,
        );
      },
    );
  }

  private setResendThrottleTimer = () => {
    this.resendThrottleTimerValue = 30;
    const timer = setInterval(() => {
      if (this.resendThrottleTimerValue > 0) {
        this.resendThrottleTimerValue--;
      } else {
        clearInterval(timer);
      }
    }, 1000);
  };

  public async resendCode() {
    try {
      await this.onResendConfirmationCode();
      this.resendSuccessfullyIndication = true;
    } catch (errorCode: any) {
      runInAction(() => {
        this.resendErrorMessage = this.rootStore.i18n.t(
          this.rootStore.accountSettingsApi.errorHandling(errorCode),
        );
      });
    }
  }

  public async verifyCode() {
    try {
      if (!this.confirmCode.isValid) {
        return;
      }
      this.status = 'LOADING';
      await this.onVerifyConfirmationCode();
      this.status = 'SUCCESS';
    } catch (errorCode: any) {
      if (errorCode === ERROR_CODES.STEP_UP_AUTH_REQUIRED) {
        return this.rootStore.postLoginStore.procceedToPostAuthUrl();
      }
      this.status = 'NONE';
      this.confirmCode.addError(
        this.rootStore.i18n.t(
          this.rootStore.accountSettingsApi.errorHandling(
            errorCode,
            'validation.phone.badCode',
          ),
        ),
      );
    }
  }

  public abstract onVerifyConfirmationCode(): Promise<void>;

  public abstract onResendConfirmationCode(): Promise<void>;
}
