import { emailRegex, phoneRegex } from '@wix/wix-js-validations';
import React from 'react';
import { TFunction } from 'react-i18next';

type ValidationFunc = (str: string) => boolean;
type ErrorMessage = string;

export type Validation = (string | undefined | React.ReactElement)[] | boolean;
export type Constraint = [ValidationFunc, ErrorMessage];

const regex =
  (reg: RegExp): ValidationFunc =>
  (str: string) =>
    reg.test(str);
const minimum =
  (num: number): ValidationFunc =>
  (str: string) =>
    !!str && str.length >= num;
const maximum =
  (num: number): ValidationFunc =>
  (str: string) =>
    !!str && str.length <= num;
const required = minimum(1);
const number = regex(phoneRegex);
const email = regex(emailRegex);

export const validate =
  (roles: Constraint[]) =>
  (str: string): Validation => {
    if (!roles || roles.length === 0) {
      return false;
    }
    const validated = roles.map(([validationFunction, errorMessage]) =>
      validationFunction(str) ? undefined : errorMessage,
    );
    // Clear valid items
    const clearedValidated = validated.filter((role) => role);
    return clearedValidated.length > 0 ? clearedValidated : false;
  };

export const constraints = {
  email,
  minimum,
  maximum,
  regex,
  required,
  number,
};

export const unescapeHtmlEntities = (str: string = '') => {
  return (
    new DOMParser()?.parseFromString(str, 'text/html')?.documentElement
      ?.textContent ?? ''
  );
};

export const isValidUrl = (str: string) => {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const generateEmailFieldConstraints = (t: TFunction): Constraint[] => [
  [constraints.required, t(ERROR_TRANSLATION_KEYS.REQUIRED)],
  [constraints.email, t(ERROR_TRANSLATION_KEYS.EMAIL_INVALID)],
];

export const PASSWORD_MIN_LENGTH_OLD = 4;
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 100;

export const ERROR_TRANSLATION_KEYS = {
  PASSWORDS_NOT_MATCH: 'validation.password.match',
  EMAIL_INVALID: 'errors.email.invalid',
  REQUIRED: 'error.required_field',
};
