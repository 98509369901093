import { action, makeObservable } from 'mobx';
import { postMessageToParent } from '../utils/general';
import { RootStore } from './root';

export class ModalModeHandlerStore {
  private readonly rootStore: RootStore;
  private closeModalHandlers: {
    DEFAULT: () => void;
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.closeModalHandlers = {
      DEFAULT: () => this.postCloseMessage(),
    };
    makeObservable(this, {
      onCloseModal: action.bound,
    });
  }

  public handleErrorReport(error: any) {
    if (!this.rootStore.displayStore.isFullScreenMode) {
      this.onAuthFailed(error);
    }
  }

  public onAuthCompleted(redirectUrl: string) {
    postMessageToParent({ id: 'authentication_completed', redirectUrl });
  }

  public onAuthFailed(error: any) {
    postMessageToParent({ id: 'authentication_failed', error });
  }

  public onCloseModal(method = 'DEFAULT') {
    this.closeModalHandlers[method]?.();
  }

  private postCloseMessage() {
    postMessageToParent({ id: 'close' });
  }
}
