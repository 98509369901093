import { observer } from 'mobx-react';
import React, { ReactNode, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  ButtonPriority,
  CustomModalLayout,
  Text,
  TextButtonWeight,
} from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import { MainHeader } from '../MainHeader/MainHeader';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import s from './PostLogin.scss';
import { ConfirmationModal } from './ConfirmationModal';

interface BackButton {
  show: boolean;
  action?: () => void;
}
interface PostLoginModalProps {
  title: React.ReactNode;
  subtitle?: string;
  sideActions?: ReactNode;
  primaryButtonText: string | ReactNode;
  secondaryButtonText?: string;
  primaryButtonAction: () => void;
  secondaryButtonAction?: () => void;
  primaryButtonDisabled?: boolean;
  primaryButtonHidden?: boolean;
  secondaryButtonHidden?: boolean;
  primaryButtonPriority?: ButtonPriority;
  children?: ReactNode;
  backButton?: BackButton;
  width?: number;
  height?: number;
  aggressive?: boolean;
  footerText?: string;
  footerWeight?: TextButtonWeight;
  stepName?: string;
  removeContentPadding?: boolean;
  minHeight?: string;
}

const PostLoginModal: React.FC<PostLoginModalProps> = observer(
  ({
    children,
    title,
    subtitle,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonAction,
    secondaryButtonAction,
    secondaryButtonHidden,
    primaryButtonDisabled,
    primaryButtonPriority,
    primaryButtonHidden,
    sideActions,
    width,
    height,
    backButton,
    aggressive,
    footerText,
    footerWeight,
    stepName,
    removeContentPadding,
    minHeight,
  }) => {
    const { rootStore } = useContext(AppContextProvider);
    const { t } = useTranslation();

    useEffect(() => {
      rootStore.dealerApi.sendDealerViewEvent(rootStore.postLoginStore.postLoginOffer.offerGuid)
    }, [])

    const stepClassName = s[stepName?.toLowerCase?.() ?? ''] ?? '';
    console.log(`stepClassName: ${stepClassName}`);
    return (
      <Box direction="vertical" width="100%" align="center">
        <MainHeader showBackButton={false} />
        <Box marginTop={20}>
          <CustomModalLayout
            removeContentPadding={removeContentPadding}
            className={stepClassName}
            dataHook={dataHooks.postLogin.common.modalContainer}
            title={title}
            subtitle={subtitle}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            primaryButtonOnClick={primaryButtonAction}
            primaryButtonProps={{
              disabled: primaryButtonDisabled,
              priority: primaryButtonPriority,
              className: primaryButtonHidden ? s.hideButton : '',
            }}
            secondaryButtonProps={{
              className: secondaryButtonHidden ? s.hideButton : '',
            }}
            secondaryButtonOnClick={secondaryButtonAction}
            width={width ?? '510px'}
            height={height ?? ''}
            content={
              <div
                className={s.customModalLayoutContent}
                style={{ minHeight: minHeight || '180px' }}
              >
                {children}
              </div>
            }
            showHeaderDivider
            footnote={
              <Box align="center" width="100%">
                <ThemedText size="small" weight={footerWeight ?? 'thin'}>
                  {aggressive ? (
                    <Trans i18nKey="post.login.aggressive.footer">
                      <ThemedTextButton
                        dataHook={dataHooks.postLogin.common.skip}
                        as="a"
                        onClick={rootStore.postLoginStore.showLater}
                        size="small"
                        skin="standard"
                      >
                        Skip
                      </ThemedTextButton>
                      <Text>
                        for now and leave my account's security at risk.
                      </Text>
                    </Trans>
                  ) : (
                    <Trans i18nKey={footerText ?? 'post.login.footer'}>
                      Have more to update? Go to
                      <ThemedTextButton
                        dataHook={
                          dataHooks.postLogin.common.accountSettingsTextButton
                        }
                        as="a"
                        onClick={() => {
                          rootStore.postLoginStore.sendAccountSettingBiEvents();
                          rootStore.navigationStore.redirect(
                            'https://manage.wix.com/account/account-settings',
                          );
                        }}
                        size="small"
                        weight={footerWeight ?? 'thin'}
                        skin="standard"
                      >
                        Account Settings
                      </ThemedTextButton>
                    </Trans>
                  )}
                </ThemedText>
              </Box>
            }
            sideActions={
              sideActions ?? backButton?.show ? (
                <ThemedTextButton
                  dataHook={dataHooks.postLogin.common.sideActionTextButton}
                  as="a"
                  onClick={() => {
                    backButton?.action?.();
                    rootStore.navigationStore.goBack();
                  }}
                >
                  {`< ${t('post.login.back')}`}
                </ThemedTextButton>
              ) : undefined
            }
          />
        </Box>
        <ConfirmationModal />
      </Box>
    );
  },
);

export default PostLoginModal;
