import {
  signupSubmit,
  loginSignupPageClickOnSwitchForm,
} from '@wix/bi-logger-hls2/v2';
import { action, computed, makeObservable, observable } from 'mobx';
import { ROUTES } from '../routes';
import {
  AUTH_METHODS_BY_MAIL,
  CaptchaStatus,
  GET_USER_ACCOUNTS_CONTEXT,
  LOGIN_PAGE_CONTEXT,
  SEARCH_PARAMS,
  SIGNUP_PAGE_CONTEXT,
} from '../utils/constants';
import {
  ERROR_CODES,
  extractErrorKeyByErrorCode,
  extractServerErrorCode,
} from '../utils/errorHandler';
import {
  Constraint,
  ERROR_TRANSLATION_KEYS,
  constraints,
  generateEmailFieldConstraints,
} from '../utils/validators';
import { CAPTCHA_ACTIONS } from './captcha';
import { FormField } from './formField';
import { LOGIN_FLOWS } from './login';
import { RootStore } from './root';
import { Values } from '../types';
import { PasswordFormField } from './passwordFormField';
import webBiLogger from '@wix/web-bi-logger';

export const SIGNUP_FLOWS = {
  FROM_LOGIN: 'FROM_LOGIN',
  DEFAULT: 'DEFAULT',
} as const;
export type ISignupFlows = Values<typeof SIGNUP_FLOWS>;

const MAP_FLOWS_TO_KEYS = {
  [SIGNUP_FLOWS.FROM_LOGIN]: 'signup.description.email_not_exists',
  [SIGNUP_FLOWS.DEFAULT]: undefined,
};

const MAP_CUSTOMIZED_FLOWS_TO_KEYS = {
  [SIGNUP_FLOWS.FROM_LOGIN]: 'signup.description.email_not_exists',
  [SIGNUP_FLOWS.DEFAULT]: undefined,
};

export class SignupStore implements AuthStore {
  private readonly rootStore: RootStore;
  private captchaAdded: CaptchaStatus = {
    [LOGIN_PAGE_CONTEXT]: false,
    [SIGNUP_PAGE_CONTEXT]: false,
    [GET_USER_ACCOUNTS_CONTEXT]: false,
  };
  public emailField: FormField;
  public emailRepeatField: FormField;
  public passwordField: PasswordFormField;
  public passwordRepeatField: PasswordFormField;
  public isEmailSignupMode = false;
  public readonly presetSubtitleKey?: string;
  private SIGNUP_INTERACTION_NAME = 'email-signup';
  public isLoading: boolean = false;
  public flow: ISignupFlows = SIGNUP_FLOWS.DEFAULT;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const { i18n } = this.rootStore;
    const emailRules: Constraint[] = generateEmailFieldConstraints(
      i18n.t.bind(i18n),
    );

    const fieldRequiredConstraint: Constraint[] = [
      [constraints.required, i18n.t(ERROR_TRANSLATION_KEYS.REQUIRED)],
    ];

    this.emailField = new FormField(
      this.rootStore.userDataStore.email,
      emailRules,
    );
    this.emailRepeatField = new FormField('', emailRules);
    this.passwordField = new PasswordFormField('', fieldRequiredConstraint);
    this.passwordRepeatField = new PasswordFormField(
      '',
      fieldRequiredConstraint,
    );
    this.presetSubtitleKey =
      this.rootStore.displayStore.preset?.signup?.subtitleKey;

    makeObservable(this, {
      emailField: observable,
      emailRepeatField: observable,
      passwordField: observable,
      isLoading: observable,
      flow: observable,
      isEmailSignupMode: observable,
      subtitleKey: computed,
      titleKey: computed,
      signup: action.bound,
      clear: action.bound,
      onInitFields: action.bound,
      handleEmailExistsError: action.bound,
    });

    if (this.rootStore.socialAuthStore.isUnsupportedAgentForSocialAuth) {
      this.isEmailSignupMode = true;
    }
  }

  clear(): void {
    this.emailField.clear();
    this.emailRepeatField.clear();
    this.passwordField.clear();
    this.passwordRepeatField.clear();
    this.isLoading = false;
    this.captchaAdded = {
      [LOGIN_PAGE_CONTEXT]: false,
      [SIGNUP_PAGE_CONTEXT]: false,
      [GET_USER_ACCOUNTS_CONTEXT]: false,
    };
  }

  onNavigateToSignup(flow: ISignupFlows = SIGNUP_FLOWS.DEFAULT) {
    this.flow = flow;
    this.onInitFields();
    this.rootStore.navigationStore.navigate(
      ROUTES.SIGNUP_PASSWORD,
      this.flow === SIGNUP_FLOWS.FROM_LOGIN ? 'Signup - from login' : undefined,
    );
  }

  onInitFields(): void {
    this.clear();
    const defaultEmail = this.rootStore.navigationStore.getQueryParam(
      SEARCH_PARAMS.DEFAULT_EMAIL,
    );
    this.emailField.value = this.rootStore.userDataStore.email;
    setTimeout(() => {
      this.emailRepeatField.value = defaultEmail.length ? defaultEmail : '';
    }, 1);
  }

  get titleKey() {
    const { signup } = this.rootStore.displayStore.preset;
    if (signup?.titleKey) {
      return signup.titleKey;
    }
    const shouldShowSignupContentVariant = this.rootStore.experiments.enabled('specs.ident.SignupTitleAB')
    if (shouldShowSignupContentVariant) {
      return 'signup.title.createYourAccount';
    }
    return 'signup.title';
  }

  get subtitleKey() {
    const mapper = MAP_FLOWS_TO_KEYS;
    const defaultKey = mapper[SIGNUP_FLOWS.DEFAULT];
    const useDefaultKey =
      this.flow === SIGNUP_FLOWS.DEFAULT || !mapper[this.flow];
    return useDefaultKey ? defaultKey : mapper[this.flow];
  }

  async signup() {
    this.rootStore.biLogger.report(signupSubmit({}));
    if (!this.isSubmittable) {
      this.markFormAsDirty();
      return;
    }
    this.rootStore.fedopsLogger.interactionStarted(
      this.SIGNUP_INTERACTION_NAME,
    );
    this.isLoading = true;
    const recaptchaParams =
      await this.rootStore.captchaStore.handleRecaptchaExecution({
        captchaAdded: this.captchaAdded[SIGNUP_PAGE_CONTEXT],
        action: CAPTCHA_ACTIONS.SIGNUP,
      });
    this.rootStore.apiStore
      .signup({
        ...this.applyAdditionalSignupParams({
          email: this.emailField.value,
          password: this.passwordField.value,
          ...recaptchaParams,
        }),
      })
      .then((data) => {
        if (data.success) {
          this.rootStore.fedopsLogger.interactionEnded(
            this.SIGNUP_INTERACTION_NAME,
          );
          this.rootStore.biLogger = webBiLogger.factory().logger();
          this.rootStore.postLoginStore.biLogger = webBiLogger
            .factory()
            .logger();
          return this.rootStore.navigationStore.postSignup(data?.payload ?? {});
        }
        const errorCode = extractServerErrorCode(data);
        if (errorCode !== ERROR_CODES.GENERAL_ERROR_CODE) {
          this.rootStore.fedopsLogger.interactionEnded(
            this.SIGNUP_INTERACTION_NAME,
          );
        }
        if (
          errorCode === ERROR_CODES.EMAIL_ALREADY_EXISTS ||
          errorCode === ERROR_CODES.EMAIL_ALREADY_EXISTS_IAM
        ) {
          return this.handleEmailExistsError();
        }
        if (errorCode === ERROR_CODES.STUDIO_ACCESS_DENIED) {
          return this.rootStore.navigationStore.redirect(
            'https://www.wix.com/studio/waitlist/early-access/access-denied',
          );
        }
        this.isLoading = false;
        this.captchaAdded[SIGNUP_PAGE_CONTEXT] =
          this.rootStore.captchaStore.createOrResetCaptchaIfNeeded(
            errorCode,
            this.captchaAdded[SIGNUP_PAGE_CONTEXT],
          );
        if (!this.rootStore.captchaStore.isCaptchaServerError(errorCode)) {
          this.emailField.addError(
            this.rootStore.i18n.t(
              extractErrorKeyByErrorCode(errorCode, 'errors.general.signup'),
            ),
          );
          this.rootStore.modalModeHandlerStore.handleErrorReport({ errorCode });
        }
        this.isLoading = false;
      })
      .catch((error) => {
        this.rootStore.modalModeHandlerStore.handleErrorReport(error);
        this.isLoading = false;
        console.error(error);
      });
  }

  public async handleEmailExistsError() {
    const handleError = () => {
      const { userDataStore, loginStore } = this.rootStore;
      userDataStore.setEmail(this.emailField.value);
      loginStore.onNavigateToLogin(LOGIN_FLOWS.FROM_SIGNUP);
      this.isLoading = false;
    };
    const recaptchaParams =
      await this.rootStore.captchaStore.handleRecaptchaExecution({
        captchaAdded: this.captchaAdded[LOGIN_PAGE_CONTEXT],
        action: CAPTCHA_ACTIONS.LOGIN,
      });
    this.rootStore.apiStore
      .login({
        email: this.emailField.value,
        password: this.passwordField.value,
        ...recaptchaParams,
      })
      .then((data) => {
        if (data.success) {
          return this.rootStore.navigationStore.postLogin(data?.payload);
        }
        const errorCode = data.errorCode.toString();
        if (errorCode === ERROR_CODES.SECOND_FACTOR_REQUIRED && data.payload) {
          this.rootStore.twoFactorAuthStore.setAuthParams(data.payload);
          this.rootStore.navigationStore.navigate(ROUTES.TWO_FACTOR_AUTH);
          return;
        } else if (
          this.rootStore.captchaStore.isCaptchaServerError(errorCode)
        ) {
          this.isLoading = false;
          this.captchaAdded[LOGIN_PAGE_CONTEXT] =
            this.rootStore.captchaStore.createOrResetCaptchaIfNeeded(
              errorCode,
              this.captchaAdded[LOGIN_PAGE_CONTEXT],
            );
          return;
        } else if (
          this.rootStore.loginStore.shouldFetchAllAccountAfterFailedLogin(
            errorCode,
          )
        ) {
          return this.handleEmailExistsWithNoPasswordError();
        }
        handleError();
      })
      .catch(() => {
        handleError();
      });
  }

  private async handleEmailExistsWithNoPasswordError() {
    this.rootStore.userDataStore.setEmail(this.emailField.value);
    const { success, errorCode } =
      await this.rootStore.loginStore.generalHandleUserWithNoPassword({
        captchaAdded: this.captchaAdded[GET_USER_ACCOUNTS_CONTEXT],
        email: this.emailField.value,
        authFlowAdditionalParmas: { showAlreadyExistsNote: true },
        originAuthMethod: AUTH_METHODS_BY_MAIL.SIGNUP_PASSWORD,
      });
    this.isLoading = false;

    if (!success) {
      this.emailField.addError(
        this.rootStore.i18n.t(extractErrorKeyByErrorCode(errorCode)),
      );
      this.captchaAdded[GET_USER_ACCOUNTS_CONTEXT] =
        this.rootStore.captchaStore.createOrResetCaptchaIfNeeded(
          errorCode,
          this.captchaAdded[GET_USER_ACCOUNTS_CONTEXT],
        );
    }
  }

  public updateEmailFieldMatchError() {
    if (
      !this.doFieldsMatch(this.emailField, this.emailRepeatField) &&
      this.emailRepeatField.isTouched
    ) {
      !this.emailRepeatField.errors &&
        this.emailRepeatField.addError(
          this.rootStore.i18n.t(`errors.signup.emails.doNotMatch`),
        );
    } else {
      this.emailRepeatField.errors = this.emailField.errors;
    }
  }

  public updatePasswordFieldMatchError() {
    const shouldRepeatPassword = !this.rootStore.displayStore.isMobile;
    if (
      shouldRepeatPassword &&
      !this.doFieldsMatch(this.passwordField, this.passwordRepeatField) &&
      this.passwordRepeatField.isTouched
    ) {
      !this.passwordRepeatField.errors &&
        this.passwordRepeatField.addError(
          this.rootStore.i18n.t(`errors.signup.passwords.doNotMatch`),
        );
    } else {
      this.passwordRepeatField.errors = this.passwordField.errors;
    }
  }

  private doFieldsMatch(field1: FormField, field2: FormField): boolean {
    return field1.value === field2.value;
  }

  public get isSubmittable(): boolean {
    const shouldRepeatPassword = !this.rootStore.displayStore.isMobile;
    return (
      this.emailField.isValid &&
      this.emailField.value === this.emailRepeatField.value &&
      this.passwordField.isValid &&
      (!shouldRepeatPassword ||
        this.passwordField.value === this.passwordRepeatField.value) &&
      !this.isLoading
    );
  }

  applyAdditionalSignupParams(params: any) {
    const { extendObjectFromQuery } = this.rootStore.apiStore;
    const booleanParamPrecondition = (val) => val === 'true' || val === 'false';
    extendObjectFromQuery(
      params,
      SEARCH_PARAMS.SEND_EMAIL,
      booleanParamPrecondition,
    );
    extendObjectFromQuery(params, SEARCH_PARAMS.COLOR);
    extendObjectFromQuery(params, SEARCH_PARAMS.USER_TOKEN);
    extendObjectFromQuery(params, SEARCH_PARAMS.REFERRAL_INFO);
    extendObjectFromQuery(params, SEARCH_PARAMS.INVITE_TOKEN);
    extendObjectFromQuery(
      params,
      SEARCH_PARAMS.IS_STUDIO,
      booleanParamPrecondition,
    );
    return params;
  }

  private markFormAsDirty() {
    this.emailField.markFieldAsDirty();
    this.emailRepeatField.markFieldAsDirty();
    this.passwordField.markFieldAsDirty();
    this.passwordRepeatField.markFieldAsDirty();
  }

  public reportSwitchToLogin() {
    this.rootStore.biLogger.report(
      loginSignupPageClickOnSwitchForm({
        switch_to: 'login',
        context: 'signup',
      }),
    );
  }
}
