import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Divider } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import CustomLoader from '../Common/CustomLoader';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import s from './PostLogin.scss';
import PostLoginModal from './PostLoginModal';

const ConfirmEmail: React.FC<{}> = observer(() => {
  const {
    rootStore: { confirmEmailStore, postLoginStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <PostLoginModal
      secondaryButtonText={t('post.login.not.now')}
      secondaryButtonAction={confirmEmailStore.showLater}
      title={t('post.login.confirm.account.email.title')}
      subtitle={t('post.login.confirm.account.email.subtitle')}
      primaryButtonAction={() =>
        !confirmEmailStore.isSubmmited && confirmEmailStore.confirmEmail()
      }
      primaryButtonText={
        confirmEmailStore.isSubmmited ? (
          <CustomLoader text={t('post.login.confirm')} status="loading" />
        ) : (
          t('post.login.confirm')
        )
      }
    >
      <>
        <Box paddingBottom={2} direction="vertical">
          <Box paddingBottom={2}>
            <ThemedText>{t('post.login.your.account.email')}</ThemedText>
          </Box>
          <div>
            <ThemedText
              weight="bold"
              dataHook={dataHooks.postLogin.confirmEmail.email}
            >
              {postLoginStore.userDetails?.email}
            </ThemedText>
          </div>
        </Box>
        <Divider />
        <Box paddingTop={4} paddingBottom={10} className={s.changeEmailLink}>
          <Trans i18nKey="post.login.change.email.link">
            <ThemedText size="tiny" color="#878787">
              Not your current email addres?
            </ThemedText>
            <ThemedTextButton
              dataHook={dataHooks.postLogin.confirmEmail.changeEmailLink}
              as="a"
              size="tiny"
              underline="always"
              onClick={confirmEmailStore.changeEmail}
              skin="standard"
            >
              Change Email
            </ThemedTextButton>
          </Trans>
        </Box>
      </>
    </PostLoginModal>
  );
});

export default ConfirmEmail;
