import { makeObservable, observable, override } from 'mobx';
import { POST_LOGIN_ROUTES } from '../routes';
import {
  ConfirmaitonCodeActions,
  POST_LOGIN_OFFERS,
  Values,
} from '../utils/constants';
import { Constraint, constraints } from '../utils/validators';
import { FormField } from './formField';
import { RootStore } from './root';
import { VerifyCodeStore } from './verifyCode';
import {
  postLoginChangeDetailsClickedContinue,
  postLoginResendCode,
  postLoginVerifyDetails,
} from '@wix/bi-logger-post-login/v2';

export class ChangeAccountEmailStore extends VerifyCodeStore {
  public verificationId: string;
  public emailInput: FormField;
  public confirmEmailInput: FormField;
  public isSubmmited: boolean = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      emailInput: observable,
      confirmEmailInput: observable,
      isSubmmited: observable,
      onResendConfirmationCode: override,
      onVerifyConfirmationCode: override,
    });
    const rules: Constraint[] = [
      [
        constraints.email,
        this.rootStore.i18n.t('loginInfo.email.input.errorInfo'),
      ],
    ];
    this.emailInput = new FormField('', rules);
    this.confirmEmailInput = new FormField('', [
      [
        constraints.minimum(4),
        this.rootStore.i18n.t('loginInfo.email.input.errorInfo'),
      ],
    ]);
  }

  public async onResendConfirmationCode() {
    this.sendConfirmationCode(ConfirmaitonCodeActions.RESEND);
  }

  public async sendConfirmationCode(
    clickAction: Values<
      typeof ConfirmaitonCodeActions
    > = ConfirmaitonCodeActions.SEND,
  ) {
    if (this.isSubmittable) {
      this.isSubmmited = true;
      try {
        const emailExistResponse =
          await this.rootStore.accountSettingsApi.checkIfEmailExist(
            this.emailInput.value,
          );

        if (emailExistResponse?.isEmailExist?.isExists) {
          this.emailInput.addError(
            this.rootStore.i18n.t('email_already_exists'),
          );
          this.isSubmmited = false;
          return;
        }

        const response =
          await this.rootStore.accountSettingsApi.sendVerificationCode({
            email: this.emailInput.value,
            incognito: true,
            isActionTagRequired: true,
          });

        this.verificationId = response?.data?.verificationId;
        if (clickAction === ConfirmaitonCodeActions.SEND) {
          this.rootStore.biLogger.report(
            postLoginChangeDetailsClickedContinue({
              methodType: 'Email',
            }),
          );
          this.rootStore.navigationStore.navigate(
            POST_LOGIN_ROUTES.CHANGE_ACCOUNT_EMAIL_VERIFY_CODE,
          );
        } else {
          this.rootStore.biLogger.report(
            postLoginResendCode({
              flow_type: 'Confirm Email',
              methodType: 'Email',
            }),
          );
        }

        this.isSubmmited = false;
        return response;
      } catch (error: any) {
        this.isSubmmited = false;

        if (clickAction === ConfirmaitonCodeActions.RESEND) {
          this.rootStore.biLogger.report(
            postLoginResendCode({
              flow_type: 'Confirm Email',
              methodType: 'Email',
            }),
          );
          throw error;
        }
        this.rootStore.biLogger.report(
          postLoginChangeDetailsClickedContinue({
            error_info: error,
            methodType: 'Email',
          }),
        );
        this.emailInput.addError(
          this.rootStore.i18n.t('ops_something_went_wrong'),
        );
      }
    }
  }

  public clean = () => {
    this.confirmCode.clear();
  };

  public async onVerifyConfirmationCode() {
    try {
      const success = await this.rootStore.accountSettingsApi.updateEmail({
        confirmationCode: this.confirmCode.value,
        verificationId: this.verificationId,
      });

      if (success) {
        if (
          this.rootStore.postLoginStore.postLoginOffer.asset.creative
            .identifier === POST_LOGIN_OFFERS.CONFIRM_DETAILS
        ) {
          this.rootStore.biLogger.report(
            postLoginVerifyDetails({
              flow_type: 'Confirm account details',
              methodType: 'Email',
            }),
          );
          this.rootStore.confirmDetailsStore.isEmailVerified = true;
          this.rootStore.navigationStore.navigate(
            POST_LOGIN_ROUTES.CONFIRM_DETAILS,
          );
          return;
        } else {
          this.rootStore.biLogger.report(
            postLoginVerifyDetails({
              flow_type: 'Confirm Email',
              methodType: 'Email',
            }),
          );
        }
        this.rootStore.postLoginStore.offerSucceed();
        return;
      }

      const response = {
        success,
        data: {
          errorCode: 8005,
        },
      };
      const error = this.rootStore.accountSettingsApi.handleError(response);
      throw error;
    } catch (error: any) {
      if (
        this.rootStore.postLoginStore.postLoginOffer.asset.creative
          .identifier === POST_LOGIN_OFFERS.CONFIRM_DETAILS
      ) {
        this.rootStore.biLogger.report(
          postLoginVerifyDetails({
            error_info: error,
            flow_type: 'Confirm account details',
            methodType: 'Email',
          }),
        );
      } else {
        this.rootStore.biLogger.report(
          postLoginVerifyDetails({
            error_info: error,
            flow_type: 'Confirm Email',
            methodType: 'Email',
          }),
        );
      }
      throw error;
    }
  }

  private doFieldsMatch(): boolean {
    return this.emailInput.value === this.confirmEmailInput.value;
  }

  public updateFieldMatchError() {
    if (!this.doFieldsMatch() && this.confirmEmailInput.isTouched) {
      !this.confirmEmailInput.errors &&
        this.confirmEmailInput.addError(
          this.rootStore.i18n.t(`post.login.confirmEmail.error.doNotMatch`),
        );
    } else {
      this.confirmEmailInput.errors = false;
    }
  }

  public clearFieldErrors(field: FormField) {
    field.errors = false;
  }

  public get isSubmittable(): boolean {
    return (
      this.emailInput.isValid &&
      this.emailInput.value === this.confirmEmailInput.value
    );
  }
}
