import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import s from './MainHeader.scss';
import { Box, Divider, TextButton } from '@wix/design-system';
import { ArrowLeft } from '@wix/wix-ui-icons-common';
import { dataHooks } from '../../dataHooks';
import { Logo } from '../Logo/Logo';
import { AppContextProvider } from '../AppLoader';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DEFAULT_LOGO_HEIGHT } from '../../utils/presets';

export interface MainHeaderProps {
  secondaryLogoUrl?: string;
  showBackButton?: boolean;
  backButtonCallback?: Function;
}

export const MainHeader: React.FC<MainHeaderProps> = observer(
  ({ secondaryLogoUrl, showBackButton = true, backButtonCallback }) => {
    const {
      rootStore: {
        displayStore: { preset, isMobile },
        onHeaderBackButtonClicked,
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    const logoHeight =
      (isMobile ? preset?.logo?.mobile?.height : preset?.logo?.height) ||
      DEFAULT_LOGO_HEIGHT;
    return (
      <Box
        className={classNames(s.mainHeader, {
          [s.centered]: preset?.logo?.position === 'center',
        })}
        dataHook={dataHooks.mainHeader.container}
        verticalAlign="space-between"
        direction="vertical"
        align={secondaryLogoUrl ? 'left' : 'center'}
      >
        <Box
          className={classNames({[s.logosContainer]: true, [s.headerHeight]: preset?.logo?.position !== 'center'})}
          verticalAlign="middle"
          align={preset?.logo?.position ? preset?.logo?.position : 'left'}
          direction="horizontal"
          width="100%"
        >
          <Logo
            logoUrl={preset.logo?.src}
            height={logoHeight}
            targetUrl={preset?.logo?.url}
            alt={preset?.logo?.alt}
            position={preset?.logo?.position}
          />
          {secondaryLogoUrl && (
            <>
              <Box height={logoHeight}>
                <Divider direction="vertical" />
              </Box>
              <Logo logoUrl={secondaryLogoUrl} secondary position={preset?.logo?.position}/>
            </>
          )}
        </Box>
        {showBackButton && !isMobile && (
          <Box direction="vertical" className={s.backButtonContainer}>
            <TextButton
              dataHook={dataHooks.app.backBtn}
              prefixIcon={<ArrowLeft />}
              size="medium"
              className={classNames(s.backButton, s.a11yFocus)}
              weight="normal"
              skin="dark"
              underline="none"
              onClick={() => onHeaderBackButtonClicked(backButtonCallback)}
            >
              {t('dialogHeader.backButton.text')}
            </TextButton>
          </Box>
        )}
      </Box>
    );
  }
);
