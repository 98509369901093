import { action, makeObservable, observable, when } from 'mobx';
import { ROUTES } from '../routes';
import {
  TwoFactorAuthMethods,
  TwoFactorAuthPhoneDeliveryMethods,
} from '../utils/constants';
import { ERROR_CODES, extractServerErrorCode } from '../utils/errorHandler';
import { postMessageToParent } from '../utils/general';
import { AuthenticateByCodeStore } from './authenticateByCode';
import { RootStore } from './root';
import {
  loginTwoFactorAuthenticationResendCodeClick,
  loginTwoFactorAuthenticationVerifyClick,
} from '@wix/bi-logger-hls2/v2';

export class StepUpAuthStore extends AuthenticateByCodeStore {
  readonly allowRememberMe: boolean = false;
  readonly dialogTitleKey: string = 'enter_code.title';
  actionTag: string;
  verificationId?: string;
  state?: string;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.SUBMIT_INTERACTION_NAME = 'step-up-verify-code';
    this.currentTwoFAMethod = TwoFactorAuthMethods.Phone;
    this.init();
    makeObservable(this, {
      currentTwoFAMethod: observable,
      enabledTwoFAMethods: observable,
      twoFAHint: observable,
      actionTag: observable,
      setAuthParams: action,
      verify: action,
      resendCode: action.bound,
      enableAccess: action.bound,
      initiate: action,
      init: action,
    });
  }

  async init() {
    const { navigationStore } = this.rootStore;
    this.actionTag = navigationStore.getQueryParam('stepUpAction');
    this.state = navigationStore.getQueryParam('stepUpState');
    if (!this.actionTag) {
      return;
    }
    navigationStore.navigate(ROUTES.STEP_UP_INITIATION);
    const data = await this.initiate();
    when(this.enableAccess, () => {
      if (data?.errorCode) {
        this.handleError(extractServerErrorCode(data));
      } else {
        navigationStore.navigate(ROUTES.STEP_UP);
      }
    });
  }

  setAuthParams({
    actionTag,
    verificationId,
  }: {
    actionTag: string;
    verificationId?: string;
  }) {
    this.actionTag = actionTag;
    this.verificationId = verificationId;
  }

  async resendCode(
    options: { phoneDeliveryMethod?: string } = {
      phoneDeliveryMethod: TwoFactorAuthPhoneDeliveryMethods.SMS,
    },
  ) {
    this.rootStore.biLogger.report(
      loginTwoFactorAuthenticationResendCodeClick({
        flow_type: this.currentTwoFAMethod,
        tfa_type: 'step-up',
        reason: 'login'
      }),
    );
    try {
      const { errorCode } = await this.initiate(options);
      return errorCode;
    } catch (error: any) {
      return extractServerErrorCode(error);
    }
  }

  async initiate(
    options: { phoneDeliveryMethod?: string } = {
      phoneDeliveryMethod: TwoFactorAuthPhoneDeliveryMethods.SMS,
    },
  ): Promise<{ success: boolean; errorCode?: string }> {
    const handleInitError = (error?: any) => {
      error && postMessageToParent({ error });
      return {
        success: false,
        errorCode: error || ERROR_CODES.GENERAL_ERROR_CODE,
      };
    };
    try {
      const data = await this.rootStore.apiStore.initiateStepUpAuth({
        actionTag: this.actionTag,
        phoneDeliveryMethod: options.phoneDeliveryMethod,
      });
      if (!data) {
        return handleInitError();
      }
      this.verificationId = data.verificationId;
      this.currentTwoFAMethod = data.methodType;
      this.twoFAHint = data.mfaHint;
      return { success: true };
    } catch (error: any) {
      return handleInitError(this.extractErrorCode(error));
    }
  }

  verify({ code }: { code: string }) {
    const handleError = (error?: string) => {
      error && postMessageToParent({ error });
      this.handleError(error || ERROR_CODES.GENERAL_ERROR_CODE);
    };
    this.rootStore.biLogger.report(
      loginTwoFactorAuthenticationVerifyClick({
        tfa_type: 'step-up',
        flow_type: this.currentTwoFAMethod,
      }),
    );
    return this.rootStore.apiStore
      .executeStepUpAuth({
        actionTag: this.actionTag,
        code,
        verificationId: this.verificationId,
        state: this.state,
      })
      .then((data) => {
        if (data?.token) {
          postMessageToParent({ token: data.token });
          return;
        }
        handleError();
      })
      .catch((error) => {
        handleError(this.extractErrorCode(error));
      });
  }

  private extractErrorCode(error: any): string {
    if (error?.response?.status === 403) {
      return 'NO_USER_SESSION';
    }
    if (error?.response?.status === 428) {
      return 'METHOD_NOT_ENABLED_FOR_USER';
    }
    return error?.response?.data?.details?.applicationError?.code || '';
  }

  enableAccess() {
    return !!(this.actionTag && this.currentTwoFAMethod);
  }
}
