import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  OFFERS_TO_ROUTES,
  POST_LOGIN_OFFERS,
  SEARCH_PARAMS,
} from '../utils/constants';
import { RootStore } from './root';
import {
  postLoginConfirmAccountDetails,
  postLoginRecoveryPhoneDetails,
  postLoginMfaDetails,
  postLoginChangeYourPasswordDetails,
} from '@wix/bi-logger-post-login/v2';
import { OwnerAppStore } from './ownerApp';
import { DeviceRegistry } from '../services/DeviceRegistry';
import { i18n as i18nType } from 'i18next';
import { ConfirmationModalStore } from './confirmationModal';
import { BI_BUTTON_NAMES, EXPERIMENTS } from './constants';
import { HttpClient } from '@wix/http-client';
import Experiments from '@wix/wix-experiments';
import { NotificationStore } from '../utils/notification';
import { AccountSettingsHttpApi } from '../services/accountSettingsApi';
import { Logger } from '@wix/web-bi-logger/dist/src/types';


export class PostLoginStore {
  public postLoginOffer: Offer;
  public targetUrl: string;
  public userDetails: UserDetailsDTO;
  public userPhone: UserPhoneDTO | null;
  public isLoaded: boolean = false;
  private isCreatePasswordOfferEnabled: boolean = false;
  private is2faAggressiveOfferEnabled: boolean = false;
  private shouldShowPostLoginOwnerApp2FaEnabled: boolean = false;
  private urlParams: URLSearchParams = new URLSearchParams(window.location.search);
  private debugCase = this.urlParams.get('debugCase') ?? undefined
  public ownerAppStore: OwnerAppStore;
  public deviceRegistry: DeviceRegistry;
  public i18n: i18nType;
  public confirmationModalStore: ConfirmationModalStore;
  public experiments: Experiments;
  public notificationStore: NotificationStore;
  public accountSettingsApi: AccountSettingsHttpApi;
  biLogger: Logger;

  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
    this.notificationStore = rootStore.notificationStore;
    this.experiments = rootStore.experiments;
    this.i18n = rootStore.i18n;
    this.confirmationModalStore = new ConfirmationModalStore(this.i18n);
    this.deviceRegistry = new DeviceRegistry(new HttpClient());
    this.accountSettingsApi = rootStore.accountSettingsApi;
    this.biLogger = rootStore.biLogger;
    this.ownerAppStore = new OwnerAppStore(this);
    makeObservable(this, {
      postLoginOffer: observable,
      userDetails: observable,
      userPhone: observable,
      isLoaded: observable,
      fetchOffers: action.bound,
      fetchUserDetails: action.bound,
      fetchUserPhone: action.bound,
    });
    this.isCreatePasswordOfferEnabled = rootStore.experiments.enabled(
      EXPERIMENTS.CREATE_PASSWORD_OFFER,
    );
    this.is2faAggressiveOfferEnabled = rootStore.experiments.enabled(
      EXPERIMENTS.TWO_FA_AGGRESSIVE_OFFER,
    );
    this.shouldShowPostLoginOwnerApp2FaEnabled =
      rootStore.experiments.enabled(
        EXPERIMENTS.SHOULD_SHOW_POST_LOGIN_OWNER_APP2_FA,
      );
  }

  public fetchResources = () => {
    return Promise.all([
      this.fetchOffers(),
      this.fetchUserDetails(),
      this.fetchUserPhone(),
    ]);
  };

  public async init(targetUrl?: string) {
    if (this.isLoaded) {
      return;
    }

    if (targetUrl) {
      this.targetUrl = targetUrl;
    } else {
      const encodedTargetUrl = sessionStorage.getItem(SEARCH_PARAMS.TARGET_URL);
      this.targetUrl = encodedTargetUrl
        ? decodeURIComponent(encodedTargetUrl)
        : 'https://manage.wix.com';
    }

    if (this.rootStore.displayStore.isMobile) {
      return this.rootStore.navigationStore.proceedToPostAuthUrl(
        this.targetUrl,
      );
    }
    const postLoginOfferStr = sessionStorage.getItem(
      SEARCH_PARAMS.POST_LOGIN_OFFER,
    );
    const userDetailsStr = sessionStorage.getItem(SEARCH_PARAMS.USER_DETAILS);
    try {
      if (postLoginOfferStr && userDetailsStr) {
        this.postLoginOffer = JSON.parse(postLoginOfferStr);
        const userDetails = JSON.parse(userDetailsStr);
        this.userDetails = userDetails.userDetails;
        this.userPhone = userDetails.userPhone;
        this.cleanSession();
      } else {
        await this.fetchResources();
      }
      if (this.debugCase) {
        this.postLoginOffer = {
          offerGuid: this.debugCase,
          offerName: this.debugCase,
          asset: {
            creative: {
              identifier: this.debugCase,
            },
          },
        };
      }

      if (this.postLoginOffer) {
        await this.rootStore.managePasswordStore.fetchPasswordPolicy();
        const postLoginOfferId = this.debugCase
          ? this.debugCase
          : this.postLoginOffer.asset.creative.identifier;
        if (
          (postLoginOfferId === POST_LOGIN_OFFERS.CREATE_PASSWORD &&
            !this.isCreatePasswordOfferEnabled) ||
          (postLoginOfferId === POST_LOGIN_OFFERS.MFA_AGGRESSIVE &&
            !this.is2faAggressiveOfferEnabled) ||
          (postLoginOfferId === POST_LOGIN_OFFERS.OWNER_APP_2FA &&
            !this.shouldShowPostLoginOwnerApp2FaEnabled)
        ) {
          return this.rootStore.navigationStore.proceedToPostAuthUrl(
            this.targetUrl,
          );
        }
        this.rootStore.initPostLoginStores();
        await this.ownerAppStore.init();

        runInAction(() => {
          this.isLoaded = true;
        });
        return this.rootStore.navigationStore.navigate(
          OFFERS_TO_ROUTES[postLoginOfferId],
        );
      }
    } catch (error) {
      console.error(error);
    }
    this.rootStore.navigationStore.proceedToPostAuthUrl(this.targetUrl);
  }

  public async fetchUserDetails(): Promise<void> {
    this.userDetails = await this.rootStore.accountSettingsApi.getUserDetails();
  }

  public async fetchUserPhone() {
    this.userPhone = await this.rootStore.accountSettingsApi.getUserPhone();
  }

  public async fetchOffers() {
    const response: Offers = await this.rootStore.dealerApi.getOffers();
    if (response.offers.length > 0) {
      const offer = response.offers[0];
      this.postLoginOffer = offer;
    }
  }

  public async fetchUserWorkspaces() {
    const response =
      await this.rootStore.accountSettingsApi.fetchUserWorkspaces();
    return response.accounts;
  }

  public offerSucceed = () => {
    this.rootStore.dealerApi.sendDealerMainCtaEvent(
      this.postLoginOffer.offerGuid,
      this.targetUrl,
    );
    setTimeout(() => {
      this.procceedToPostAuthUrl();
    }, 300);
  };

  public procceedToPostAuthUrl = () => {
    this.rootStore.navigationStore.proceedToPostAuthUrl(this.targetUrl);
  };

  public showLater = () => {
    this.rootStore.dealerApi.sendDealerShowLaterEvent(
      this.postLoginOffer.offerGuid,
    );
    this.rootStore.navigationStore.proceedToPostAuthUrl(this.targetUrl);
  };

  public cleanSession() {
    sessionStorage.removeItem('targetUrl');
    sessionStorage.removeItem('postLoginOffer');
    sessionStorage.removeItem('userDetails');
  }

  public async sendAccountSettingBiEvents() {
    switch (this.postLoginOffer.asset.creative.identifier) {
      case POST_LOGIN_OFFERS.CONFIRM_DETAILS:
        this.rootStore.biLogger.report(
          postLoginConfirmAccountDetails({
            button: 'Account settings',
            flowType: 'Confirm account details',
          }),
        );
        break;
      case POST_LOGIN_OFFERS.CONFIRM_EMAIL:
        this.rootStore.biLogger.report(
          postLoginConfirmAccountDetails({
            button: 'Account settings',
            flowType: 'Confirm Email',
          }),
        );
        break;
      case POST_LOGIN_OFFERS.MFA:
        this.rootStore.biLogger.report(
          postLoginMfaDetails({
            button: 'Account settings',
            mfa_type: 'Phone',
          }),
        );
        break;
      case POST_LOGIN_OFFERS.RECOVERY_PHONE:
        this.rootStore.biLogger.report(
          postLoginRecoveryPhoneDetails({
            button: 'Account settings',
          }),
        );
        break;
      case POST_LOGIN_OFFERS.UPDATE_PASSWORD:
        this.rootStore.biLogger.report(
          postLoginChangeYourPasswordDetails({
            button: 'Account settings',
          }),
        );
        break;
      case POST_LOGIN_OFFERS.OWNER_APP_2FA:
        this.ownerAppStore.sendBiEvent(BI_BUTTON_NAMES.ACCOUNT_SETTINGS);
        break;
    }
  }
}
