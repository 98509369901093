import { create as createFedopsLogger, FedopsLogger } from '@wix/fedops-logger';
import webBiLogger from '@wix/web-bi-logger';
import { Logger } from '@wix/web-bi-logger/dist/src/types';
import { i18n as i18nType } from 'i18next';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { AccountSettingsApi } from '../services/accountSettingsApi';
import { DealerApi } from '../services/dealerApi';
import { NotificationStore } from '../utils/notification';
import { AccountRecoveryCodeStore } from './accountRecoveryCode';
import { AccountVerificationStore } from './accountVerification';
import { PhoneStore } from './phone';
import { ApiStore } from './api';
import { AppleLoginStore } from './appleLogin';
import { ApproveAndContinueStore } from './approveAndContinue';
import { CaptchaStore } from './captcha';
import { ChangeAccountEmailStore } from './changeAccountEmail';
import { ConfirmDetailsStore } from './confirmDetails';
import { ConfirmEmailStore } from './confirmEmail';
import { DisplayStore } from './display';
import { EmailStepStore } from './emailStep';
import { FacebookLoginStore } from './facebookLogin';
import { GoogleLoginStore } from './googleLogin';
import { LoginStore } from './login';
import { ManagePasswordStore } from './managePassword';
import { NavigationStore } from './navigation';
import { PostLoginStore } from './postLogin';
import { PostSocialLoginEnterEmailStore } from './postSocialLoginEnterEmail';
import { ResetPasswordStore } from './resetPassword';
import { ResetPasswordInvalidStore } from './resetPasswordInvalid';
import { SelectAccountStore } from './selectAccount';
import { SelectWorkspaceStore } from './selectWorkspace';
import { SessionId } from './sessionId';
import { SsoStore } from './sso';
import { SsoEmailAlreadyExistStore } from './ssoEmailAlreadyExist';
import { SsoEnterEmailStore } from './ssoEnterEmail';
import { StepUpAuthStore } from './stepUpAuth';
import { TagManagerStore } from './tagManager';
import { TwoFactorAuthStore } from './twoFactorAuth';
import { UserDataStore } from './userData';
import { PoliciesStore } from './policies';
import { SignupStore } from './signup';
import { SocialAuthStore } from './socialAuth';
import { loginPageOnStart, loginClickOnBack } from '@wix/bi-logger-hls2/v2';
import Experiments from '@wix/wix-experiments/';
import { DeviceLoginStore } from './deviceLogin';
import { ModalModeHandlerStore } from './modalModeHandler';
import {
  LOGIN_PAGE_CONTEXT,
  SEARCH_PARAMS,
  SIGNUP_PAGE_CONTEXT,
} from '../utils/constants';
import { ROUTES } from '../routes';
import { PasswordService } from '../services/PasswordServiceApi';
import { PasswordStrengthExtractor } from '@wix/identity-fed-common';
import { BlockedAccountStore } from './blockedAccount';

const RouteToContextTypeMapper = {
  [ROUTES.SELECT_ACCOUNT]: 'select-account',
  [ROUTES.SIGNUP_PASSWORD]: 'signup-password',
  [ROUTES.LOGIN_PASSWORD]: 'login-password',
  [ROUTES.SOCIAL_ONLY]: 'social-login',
  [ROUTES.GOOGLE_LOGIN]: 'google-onetap-login',
  [ROUTES.DEVICE_LOGIN]: 'device-login',
  [ROUTES.SSO_ENTER_EMAIL]: 'sso-enter-email',
  [ROUTES.SSO_MAIN]: 'sso-landing-page',
  [ROUTES.ACCOUNT_VERIFICATION]: 'social-account-verification',
};

const RouteToContextMapper = {
  [ROUTES.SSO_ENTER_EMAIL]: LOGIN_PAGE_CONTEXT,
  [ROUTES.SSO_MAIN]: LOGIN_PAGE_CONTEXT,
  [ROUTES.SELECT_WORKSPACE]: 'select-workspace',
  [ROUTES.SIGNUP_PASSWORD]: SIGNUP_PAGE_CONTEXT,
  [ROUTES.LOGIN_PASSWORD]: LOGIN_PAGE_CONTEXT,
  [ROUTES.GOOGLE_LOGIN]: LOGIN_PAGE_CONTEXT,
  [ROUTES.SOCIAL_ONLY]: LOGIN_PAGE_CONTEXT,
  [ROUTES.POST_LOGIN]: 'post-login',
  [ROUTES.RESET_PASSWORD]: 'reset-password',
  [ROUTES.RESET_PASSWORD_INVALID]: 'reset-password-expired',
  [ROUTES.STEP_UP_INITIATION]: 'step-up',
  [ROUTES.STEP_UP]: 'step-up',
  [ROUTES.DEVICE_LOGIN]: LOGIN_PAGE_CONTEXT,
  [ROUTES.SELECT_ACCOUNT]: LOGIN_PAGE_CONTEXT,
  [ROUTES.ACCOUNT_VERIFICATION]: LOGIN_PAGE_CONTEXT,
};

export class RootStore {
  public fedopsLogger: FedopsLogger;
  public biLogger: Logger;
  public navigationStore: NavigationStore;
  public ssoEnterEmailStore: SsoEnterEmailStore;
  public postSocialLoginEnterEmailStore: PostSocialLoginEnterEmailStore;
  public resetPasswordStore: ResetPasswordStore;
  public resetPasswordInvalidStore: ResetPasswordInvalidStore;
  public ssoStore: SsoStore;
  public userDataStore: UserDataStore;
  public approveAndContinueStore: ApproveAndContinueStore;
  public apiStore: ApiStore;
  public ssoEmailAlreadyExistStore: SsoEmailAlreadyExistStore;
  public displayStore: DisplayStore;
  public passwordStrengthExtractor: PasswordStrengthExtractor;
  public selectAccountStore: SelectAccountStore;
  public selectWorkspaceStore: SelectWorkspaceStore;
  public emailStepStore: EmailStepStore;
  public loginStore: LoginStore;
  public signupStore: SignupStore;
  public captchaStore: CaptchaStore;
  public tagManagerStore: TagManagerStore;
  public twoFactorAuthStore: TwoFactorAuthStore;
  public accountRecoveryCodeStore: AccountRecoveryCodeStore;
  public stepUpAuthStore: StepUpAuthStore;
  public appleLoginStore: AppleLoginStore;
  public facebookLoginStore: FacebookLoginStore;
  public googleLoginStore: GoogleLoginStore;
  public accountVerificationStore: AccountVerificationStore;
  public sessionId: SessionId;
  public confirmEmailStore: ConfirmEmailStore;
  public changeAccountEmailStore: ChangeAccountEmailStore;
  public confirmDetailsStore: ConfirmDetailsStore;
  public managePasswordStore: ManagePasswordStore;
  public phoneStore: PhoneStore;
  public blockedAccountStore: BlockedAccountStore;
  public isLoaded: boolean = false;
  public isLoading: boolean = false;
  public activeSocialProvider: string;
  public accountSettingsApi: AccountSettingsApi;
  public dealerApi: DealerApi;
  public notificationStore: NotificationStore;
  public postLoginStore: PostLoginStore;
  public policiesStore: PoliciesStore;
  public socialAuthStore: SocialAuthStore;
  public modalModeHandlerStore: ModalModeHandlerStore;
  public passwordService: PasswordService;
  public i18n: i18nType;
  public i18nLoaded: (i18n?: any) => Promise<any>;
  public locale: string;
  public experiments: Experiments;
  public deviceLoginStore: DeviceLoginStore;
  public isNewLoginApp: boolean = window.__IS_NEW_LOGIN__ === 'true';
  public originUrl?: string;
  public userType: string;
  public isStudio: boolean = false;

  constructor(
    accountSettingsApi: AccountSettingsApi,
    dealerApi: DealerApi,
    i18n: i18nType,
    passwordStrengthExtractor: PasswordStrengthExtractor,
    experiments: Experiments,
  ) {
    makeObservable(this, {
      isLoaded: observable,
      isLoading: observable,
      activeSocialProvider: observable,
      isNewLoginApp: observable,
      isFireAppStartReady: computed,
      onHeaderBackButtonClicked: action.bound,
      onClickRootElement: action.bound,
      setActiveSocialProvider: action.bound,
      locale: observable,
      init: action,
      setIsLoading: action,
      initStores: action,
      initPostLoginStores: action.bound,
    });

    this.fedopsLogger = createFedopsLogger('login-react-app');
    this.biLogger = webBiLogger.factory().logger();
    this.i18n = i18n;
    this.passwordStrengthExtractor = passwordStrengthExtractor;
    this.accountSettingsApi = accountSettingsApi;
    this.dealerApi = dealerApi;
    this.locale = window.__LOCALE__;
    this.experiments = experiments;
    this.passwordService = new PasswordService();
    this.init();
  }

  public async init() {
    this.setUserType();
    this.initStores();
    this.triggerWixRecorder();
    this.originUrl = document.referrer
      ? document.referrer.split('?').shift()
      : this.navigationStore.originUrl;
    this.fedopsLogger.appLoaded();
    runInAction(async () => {
      this.isLoaded = true;
      await when(() => this.isFireAppStartReady);
      const isEmailStepRoute =
        this.navigationStore.currentRoute === ROUTES.EMAIL_STEP;
      const context = isEmailStepRoute
        ? this.emailStepStore.getContext()
        : RouteToContextMapper[this.navigationStore.currentRoute];
      const contextType = isEmailStepRoute
        ? context
        : RouteToContextTypeMapper[this.navigationStore.currentRoute];
      this.biLogger.report(
        loginPageOnStart({
          origin: this.navigationStore.originUrl,
          context,
          context_type: contextType,
          referrer: this.navigationStore.originContext,
          referrer_context: this.navigationStore.loginDialogContext,
          context_button: this.navigationStore.getQueryParam('loginCompName'),
          avail_height: window.innerHeight,
          avail_width: window.innerWidth,
          screen_height: window.screen.availHeight,
          screen_width: window.screen.availWidth,
        }),
      );
    });
  }

  public async initMissingRenderParams() {
    try {
      const missingParams = await this.apiStore.getMissingRenderParams();
      window.__SSO_REDIRECT_URL__ =
        window.__SSO_REDIRECT_URL__ || missingParams.ssoRedirectUrl;
      window.__SSO_EXISTING_WIX_ACCOUNT_EMAIL__ =
        window.__SSO_EXISTING_WIX_ACCOUNT_EMAIL__ ||
        missingParams.ssoExistingWixAccountEmail;
      window.__SSO_ACCOUNT_NAME__ =
        window.__SSO_ACCOUNT_NAME__ || missingParams.ssoAccountName;
      window.__SSO_ACCOUNT_IMAGE__ =
        window.__SSO_ACCOUNT_IMAGE__ || missingParams.ssoAccountImage;
      window.__SSO_ACCOUNT_ID__ =
        window.__SSO_ACCOUNT_ID__ || missingParams.ssoAccountId;
      window.__CURRENT_LOGGED_IN_USER_EMAIL__ =
        window.__CURRENT_LOGGED_IN_USER_EMAIL__ ||
        missingParams.currentLoggedInUserEmail;
    } catch (error) {
      console.error(error);
    }
  }

  public initStores() {
    this.apiStore = new ApiStore(this);
    this.initMissingRenderParams();
    this.notificationStore = new NotificationStore();
    this.navigationStore = new NavigationStore(this);
    this.isStudio =
      this.navigationStore.getQueryParam(SEARCH_PARAMS.IS_STUDIO) === 'true';
    this.sessionId = new SessionId(this);
    this.displayStore = new DisplayStore(this);
    this.ssoEnterEmailStore = new SsoEnterEmailStore(this);
    this.postSocialLoginEnterEmailStore = new PostSocialLoginEnterEmailStore(
      this,
    );

    this.userDataStore = new UserDataStore(this);
    this.ssoStore = new SsoStore(this);
    this.approveAndContinueStore = new ApproveAndContinueStore(this);
    this.ssoEmailAlreadyExistStore = new SsoEmailAlreadyExistStore(this);
    this.selectAccountStore = new SelectAccountStore(this);
    this.selectWorkspaceStore = new SelectWorkspaceStore(this);
    this.socialAuthStore = new SocialAuthStore(this);
    this.emailStepStore = new EmailStepStore(this);
    this.loginStore = new LoginStore(this);
    this.signupStore = new SignupStore(this);
    this.captchaStore = new CaptchaStore(this);
    this.tagManagerStore = new TagManagerStore(this);
    this.twoFactorAuthStore = new TwoFactorAuthStore(this);
    this.accountRecoveryCodeStore = new AccountRecoveryCodeStore(this);
    this.stepUpAuthStore = new StepUpAuthStore(this);
    this.googleLoginStore = new GoogleLoginStore(this);
    this.appleLoginStore = new AppleLoginStore(this);
    this.facebookLoginStore = new FacebookLoginStore(this);
    this.accountVerificationStore = new AccountVerificationStore(this);
    this.postLoginStore = new PostLoginStore(this);
    this.policiesStore = new PoliciesStore(this);
    this.deviceLoginStore = new DeviceLoginStore(this);
    this.modalModeHandlerStore = new ModalModeHandlerStore(this);
    this.managePasswordStore = new ManagePasswordStore(this);
    this.resetPasswordStore = new ResetPasswordStore(this);
    this.resetPasswordInvalidStore = new ResetPasswordInvalidStore(this);
    this.blockedAccountStore = new BlockedAccountStore(this);
  }

  public initPostLoginStores() {
    this.phoneStore = new PhoneStore(this);
    this.confirmEmailStore = new ConfirmEmailStore(this);
    this.changeAccountEmailStore = new ChangeAccountEmailStore(this);
    this.confirmDetailsStore = new ConfirmDetailsStore(this);
  }

  public setIsLoading(state: boolean) {
    this.isLoading = state;
  }

  public setActiveSocialProvider(provider: string) {
    this.activeSocialProvider = provider;
  }

  get language() {
    return {
      lang: this.i18n.languages?.[1],
      locale: this.i18n.language
    };
  }

  public getActiveSocialProviderStore() {
    const providerStores: any = {
      google: this.googleLoginStore,
      facebook: this.facebookLoginStore,
      apple: this.appleLoginStore,
    };

    return providerStores[this.activeSocialProvider];
  }

  private setUserType() {
    if (window.__USER_TYPE__) {
      this.userType = window.__USER_TYPE__ ?? '';
      return;
    }
    if (window.__BASEURL__) {
      // for verticals should set baseURL to include the userType
      const baseUrlParts = window.__BASEURL__.split('/').filter((e) => e);
      this.userType = baseUrlParts.length > 1 ? baseUrlParts[0] : '';
    }
  }

  private async triggerWixRecorder() {
    if (!this.experiments.enabled('specs.ident.NewLoginUseWixRecorder')) {
      return;
    }
    if (this.experiments.enabled('specs.ident.NewLoginForceWixRecorder')) {
      await window.wixRecorder?.__forceRecording();
    }
    window.wixRecorder?.addLabel('users-new-login');
    this.addAttributeToWixRecorder(
      'isUserLoggedIn',
      (!!this.userDataStore.currentLoggedInEmail).toString(),
    );
    this.addAttributeToWixRecorder('referrer', document.referrer);
    window.wixRecorder?.withExperiments(this.experiments.all());
  }

  // TODO - Delete when finish investigating new login failure
  onClickRootElement(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (this.experiments.enabled('specs.ident.NewLoginForceWixRecorder')) {
      const _e = e?.target as any;
      this.addAttributeToWixRecorder('pageClicked', [
        _e?.className,
        _e?.innerText,
      ]);
    }
  }

  addAttributeToWixRecorder(key: string, value: string | string[]) {
    if (!this.experiments.enabled('specs.ident.NewLoginUseWixRecorder')) {
      return;
    }
    window.wixRecorder?.addCustomAttribute(`newLogin#${key}`, value);
  }

  onHeaderBackButtonClicked(callback?: Function) {
    this.biLogger.report(
      loginClickOnBack({
        step_name: this.navigationStore.currentRoute,
      }),
    );
    if (callback) {
      return callback();
    }
    this.navigationStore.goBack();
  }

  get isFireAppStartReady() {
    return !this.emailStepStore.isFetchingByDefaultEmail;
  }
}
