export const ROUTES = {
  SSO_ENTER_EMAIL: '/sso/enter-email',
  SSO_MAIN: '/sso/main',
  SSO_EMAIL_ALREADY_EXIST: '/sso/email-already-exist',
  SELECT_ACCOUNT: '/select-account',
  SELECT_WORKSPACE: '/select-workspace',
  EMAIL_STEP: '/',
  SIGNUP_PASSWORD: '/signup/password',
  SOCIAL_ONLY: '/login/social',
  LOGIN_PASSWORD: '/login',
  TWO_FACTOR_AUTH: '/2fa',
  RECOVER_ACCOUNT: '/recover-account',
  STEP_UP: '/step-up',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_CONFIRMATION: '/reset-password/confirmed',
  RESET_PASSWORD_INVALID: '/reset-password/invalid',
  ACCOUNT_VERIFICATION: '/account-verification',
  SOCIAL_ENTER_EMAIL: '/social-enter-email',
  POST_LOGIN: '/post-login',
  GOOGLE_LOGIN: '/google-login',
  STEP_UP_INITIATION: '/step-up-initiation',
  DEVICE_LOGIN: '/device-login',
  CONNECTION_SUCCESSFUL: '/connection-successful',
  DISCONNECT_SUCCESSFUL: '/disconnect-successful',
  BLOCKED_ACCOUNT: '/blocked-account',
};

export const POST_LOGIN_ROUTES = {
  CONFIRM_DETAILS: '/post-login/confirm-details',
  CONFIRM_EMAIL: '/post-login/confirm-email',
  CONFIRM_EMAIL_CODE: '/post-login/confirm-email/verify',
  CHANGE_ACCOUNT_EMAIL_VERIFY_CODE: '/post-login/change-account-email/verify',
  CHANGE_ACCOUNT_EMAIL: '/post-login/change-account-email',
  CONFIRM_DETAILS_PHONE: '/post-login/confirm-details-phone',
  CONFIRM_DETAILS_PHONE_VERIFY_CODE: '/post-login/confirm-details-phone/verify',
  RECOVERY_PHONE: '/post-login/recovery-phone',
  RECOVERY_PHONE_VERIFY_CODE: '/post-login/recovery-phone/verify',
  PHONE_2FA: '/post-login/phone-2fa',
  PHONE_2FA_AGGRESSIVE: '/post-login/phone-2fa-aggressive',
  PHONE_2FA_VERIFY_CODE: '/post-login/phone-2fa/verify',
  CHANGE_PASSWORD: '/post-login/change-password',
  CREATE_PASSWORD: '/post-login/create-password',
  OWNER_APP_2FA: '/post-login/owner-app-2fa',
};

export const DEFAULT_ROUTE = ROUTES.EMAIL_STEP;
