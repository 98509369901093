import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as SuccessfulIllustration } from '../../../../assets/images/successLarge.svg';
import { AppContextProvider } from '../../../AppLoader';
import { Box } from '@wix/design-system';

export const SuccessfulStep: React.FC = observer(() => {
  const {
    rootStore: { postLoginStore: store },
  } = useContext(AppContextProvider);
  setTimeout(() => {
    store.procceedToPostAuthUrl();
  }, 1000);
  return (
    <Box width="100%" height="100%" align="center">
      <Box direction="vertical" verticalAlign="middle">
        <SuccessfulIllustration />
      </Box>
    </Box>
  );
});
