import { FedopsLogger } from '@wix/fedops-logger';
import { HttpClient } from '@wix/http-client';
import { Logger } from '@wix/web-bi-logger/dist/src/types';
import { isNumber } from 'lodash';
import {
  ACCOUNT_SERVER_API_PATH,
  ACCOUNT_SETTINGS_API_PATH,
  USERS_API_PATH,
  OWNER_AUTHENTICATOR_PATH,
} from '../utils/constants';
import { ERROR_CODES } from '../utils/errorHandler';
import { AccountsResponse } from '@wix/ambassador-account-server/types';
import { setMyPassword } from '@wix/ambassador-identity-users-v1-user/http';
import { SetMyPasswordResponse } from '@wix/ambassador-identity-users-v1-user/types';

export type AccountSettingsApi = AccountSettingsHttpApi;

export enum Identifier {
  Recovery = 'Recovery',
  TwoFA = 'TwoFA',
}

export class AccountSettingsHttpApi {
  private readonly biLogger: Logger;
  private readonly fedopsLogger: FedopsLogger;
  private readonly defaultParams = {
    orgDocID: 'null-doc-id',
  };
  private readonly httpClient = new HttpClient();

  constructor(biLogger: Logger, fedopsLogger: FedopsLogger) {
    this.biLogger = biLogger;
    this.fedopsLogger = fedopsLogger;
    this.handleError = this.handleError.bind(this);
  }

  public OwnerApp = {
    enable: (verificationId: string, deviceId?: string) => {
      return this.httpClient
        .post(`/${ACCOUNT_SETTINGS_API_PATH}/2fa/push/enable`, {
          verificationId,
          deviceId,
        })
        .then((result) => result.data);
    },
    getStatus: (verificationId: string) => {
      return this.httpClient
        .get(
          `/${OWNER_AUTHENTICATOR_PATH}/get-challenge?verificationId=${verificationId}`,
        )
        .then((result) => result.data);
    },
  };

  public async getUserDetails(): Promise<UserDetailsDTO> {
    const res = await this.httpClient.get(
      `/${ACCOUNT_SETTINGS_API_PATH}/users`,
    );
    return res.data;
  }

  public updateEmail({
    confirmationCode,
    verificationId,
  }: {
    confirmationCode: string;
    verificationId: string;
  }): Promise<boolean> {
    const params: ConfirmEmailCodeDTO = {
      verificationCode: confirmationCode,
      verificationId,
    };

    return this.httpClient
      .put(`${USERS_API_PATH}/users/email`, params)
      .then((result) => {
        return result.status === 200;
      });
  }

  public verifyEmailCode({
    verificationCode,
    verificationId,
  }: {
    verificationCode: string;
    verificationId: string;
  }): Promise<boolean> {
    const params: ConfirmEmailCodeDTO = {
      verificationCode,
      verificationId,
    };

    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/verify-email-code`, params)
      .then((result) => {
        return result.status === 200;
      });
  }

  public getUserPhone(): Promise<UserPhoneDTO> {
    return this.httpClient
      .get(`/${ACCOUNT_SETTINGS_API_PATH}/phones`)
      .then((result) => result.data);
  }

  public authenticate(password: string) {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/authenticate`, { password })
      .then((result) => result.data);
  }

  // NOTE: not in use
  public setUserPhone(
    identifier: Identifier,
    countryCode: string,
    nationalNumber: string,
  ) {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/phones`, {
        identifier,
        countryCode,
        nationalNumber,
      })
      .then((result) => result.data);
  }

  public deleteUserPhone(identifier: Identifier, password: string = '') {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/delete-phone`, {
        identifier,
        password,
      })
      .then((result) => result.data);
  }

  public deleteUserPhoneTwoFA(password: string) {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/2fa/disable`, { password })
      .then((result) => result.data);
  }

  public sendVerificationCode({
    email,
    phone,
    deviceId,
    incognito,
    isActionTagRequired,
  }: {
    email?: string;
    phone?: PhoneDTO;
    deviceId?: string;
    incognito?: boolean;
    isActionTagRequired?: boolean;
  }): Promise<any> {
    return this.httpClient.post(
      `/${ACCOUNT_SETTINGS_API_PATH}/send-verification-code`,
      {
        phone: phone
          ? phone.internationalNumber ??
            `${phone.countryCode}${phone.nationalNumber}`
          : undefined,
        email,
        deviceId,
        incognito,
        isActionTagRequired,
      },
    );
  }

  public isPremiumUser() {
    return this.httpClient
      .get<{ isPremiumUser: boolean }>(
        `/${ACCOUNT_SETTINGS_API_PATH}/is-premium-user`,
      )
      .then((res) => res.data.isPremiumUser);
  }

  public verifyAndEnableRecoveryPhone(phone: PhoneDTO, code: string, verificationId: string) {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/recovery-phone/enable`, {
        phone,
        code,
        verificationId,
      })
      .then((result) => result.data);
  }

  public verifyAndEnablePhoneTwoFA(phone: PhoneDTO, code: string, verificationId: string) {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/2fa/phone/enable`, {
        phone,
        code,
        verificationId,
      })
      .then((result) => result.data);
  }

  public verifyAndEnableEmailTwoFA(email: string, code: string, verificationId: string) {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/2fa/email/enable`, {
        email,
        code,
        verificationId,
      })
      .then((result) => result.data);
  }

  public verifyAndEnableTotpTwoFA(sharedSecretKey: string, code: string) {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/2fa/totp/enable`, {
        sharedSecretKey,
        code,
      })
      .then((result) => result.data);
  }

  public removePhoneTwoFA() {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/2fa/phone/disable`)
      .then((result) => result.data);
  }

  public removeEmailTwoFA() {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/2fa/email/disable`)
      .then((result) => result.data);
  }

  public removeTotpTwoFA() {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/2fa/totp/disable`)
      .then((result) => result.data);
  }

  public getTwoFAPhone(): Promise<PhoneDTO> {
    return this.httpClient
      .get(`/${ACCOUNT_SETTINGS_API_PATH}/2fa`)
      .then((result) => result.data);
  }

  public updateUserRealName({ first, last }: { first: string; last: string }) {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/usersName`, { first, last })
      .then((result) => result.data);
  }

  public updatePassword(
    password: string,
    newPassword: string,
  ): Promise<SetMyPasswordResponse> {
    return this.httpClient
      .request(setMyPassword({ newPassword, currentPassword: password }))
      .then((result) => result.data);
  }

  public sendVerificationEmail(email: string) {
    return this.httpClient.post(
      `/${ACCOUNT_SETTINGS_API_PATH}/send-verification-email`,
      {
        email,
      },
    );
  }

  public checkIfEmailExist(email: string) {
    return this.httpClient
      .get(`/${ACCOUNT_SETTINGS_API_PATH}/is-email-exist?email=${email}`)
      .then((result) => result.data);
  }

  public setProfileImage(profileImage: any) {
    return this.httpClient.post(
      `/${ACCOUNT_SETTINGS_API_PATH}/users/profile-image`,
      {
        imageUrl: profileImage,
      },
    );
  }

  public deleteProfileImage() {
    return this.httpClient.delete(
      `/${ACCOUNT_SETTINGS_API_PATH}/users/profile-image`,
    );
  }

  public getProfileImage(): Promise<string> {
    return this.httpClient
      .get(`/${ACCOUNT_SETTINGS_API_PATH}/users/profile-image`)
      .then((res: any) => res.data.imageUrl);
  }

  public uploadFileToMp(uploadUrlInfo: any, file: any) {
    return this.httpClient
      .post(uploadUrlInfo.uploadUrl, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          acl: 'public',
          uploadToken: uploadUrlInfo.uploadToken,
          file,
        },
        withCredentials: true,
      })
      .then((res: any) => res.data);
  }

  public getProStatus() {
    return this.httpClient
      .get(`/${ACCOUNT_SETTINGS_API_PATH}/settings/pro-status`)
      .then((res) => res.data);
  }

  public toggleProStatus(status: string) {
    return this.httpClient.post(`/${ACCOUNT_SETTINGS_API_PATH}/pro`, {
      status,
    });
  }

  public getIsPermittedDisablePartner(): Promise<boolean> {
    return this.httpClient
      .get(`/${ACCOUNT_SETTINGS_API_PATH}/is-permitted-disable-partner`)
      .then((result) => result.data);
  }

  public getIsSSOMandatory(): Promise<boolean> {
    return this.httpClient
      .get(`/${ACCOUNT_SETTINGS_API_PATH}/isSSOMandatory`)
      .then((result) => result.data);
  }

  public fetchUserWorkspaces(): Promise<AccountsResponse> {
    return this.httpClient
      .get(`/${ACCOUNT_SERVER_API_PATH}users/my_accounts?paging.limit=2`)
      .then((result) => result.data);
  }

  public startAuthenticatorAppMethod(
    email: string,
  ): Promise<{ qrURL: string; sharedSecretKey: string }> {
    return this.httpClient
      .post(`/${ACCOUNT_SETTINGS_API_PATH}/authenticator-app/start`, {
        email,
      })
      .then((result) => result.data);
  }

  public forgotPass(email) {
    return this.httpClient.post(`/${ACCOUNT_SETTINGS_API_PATH}/resetpassword`, {
      email,
    });
  }

  public handleError(response: any) {
    const errorCode =
      response?.response?.data?.errorCode ||
      response?.data?.errorCode ||
      response?.data?.reason ||
      response?.reason;
    const errorReason = this.errorHandling(errorCode);

    return { success: false, errorCode, errorReason };
  }

  public errorHandling(
    errorCode: number | string,
    defaultMsg = 'ops_something_went_wrong',
  ) {
    const errorCodeStr =
      errorCode && isNumber(errorCode) ? errorCode.toString() : errorCode;
    switch (errorCodeStr) {
      case ERROR_CODES.PASSWORD_INCORRECT, ERROR_CODES.IAM_PASSWORD_INCORRECT:
        return 'pass_is_incorrect';
      case ERROR_CODES.EMAIL_ALREADY_EXISTS:
        return 'email_already_exists';
      case ERROR_CODES.USERNAME_ALREADY_EXISTS:
        return 'username_already_exists';
      case ERROR_CODES.INVALID_PHONE_NUMBER:
        return 'validation.phone.invalid';
      case -80000:
        return 'validation.phone.throttle';
      case 'NOT_FOUND':
        return 'validation.phone.codeExpired';
      case 'BAD_CODE':
      case '8005':
        return 'post.login.bad.code';
      case 'SEND_CODE_ERROR':
      default:
        return defaultMsg;
    }
  }

  public logoutAllDevices() {
    window.location.replace(
      'https://manage.wix.com/wix-users/auth/logoutalldevices',
    );
  }
}
