import { observer } from 'mobx-react';
import React from 'react';
import { Box, Text, TextButton } from '@wix/design-system';
import { Check } from '@wix/wix-ui-icons-common';
import { useTranslation } from 'react-i18next';

export interface ResendVerificationCodeProps {
  resendSuccessfullyIndication: boolean;
  shouldShowResendTimer: boolean;
  resendThrottleTimerValue: number;
  sendConfirmationCode: () => void;
  resendErrorMessage?: string;
  resendButtonTranslationKey?: string;
  resendIndicationTranslationKey?: string;
}

export const ResendVerificationCode: React.FC<ResendVerificationCodeProps> =
  observer(
    ({
      resendSuccessfullyIndication,
      shouldShowResendTimer,
      resendThrottleTimerValue,
      resendErrorMessage,
      sendConfirmationCode,
      resendButtonTranslationKey,
      resendIndicationTranslationKey,
    }) => {
      const { t } = useTranslation();
      return (
        <Box direction="horizontal" gap={1}>
          {(!resendSuccessfullyIndication || !shouldShowResendTimer) && (
            <TextButton
              size="medium"
              weight="thin"
              onClick={sendConfirmationCode}
            >
              {t(
                resendButtonTranslationKey ??
                  'confirmEmailModalSecondStep.confirmationCode.resendLink.text',
              )}
            </TextButton>
          )}
          {resendSuccessfullyIndication &&
            (shouldShowResendTimer ? (
              <Text size="medium" weight="thin" light secondary>
                {t(
                  resendIndicationTranslationKey ??
                    'post.login.oa.2fa.code.resend.indication',
                  {
                    time: resendThrottleTimerValue.toString(),
                  },
                )}
              </Text>
            ) : (
              <Box position="relative" bottom="3px">
                <Check
                  style={{ color: 'green' }}
                  size="18px"
                  viewBox="0 0 18 18"
                />
              </Box>
            ))}
          {resendErrorMessage && (
            <Box inline marginLeft={1}>
              <Text skin="error" size="small">
                {resendErrorMessage}
              </Text>
            </Box>
          )}
        </Box>
      );
    },
  );
